.thankyouwrapper{
  margin:0 0.5rem;
  margin-top: 0.5rem;
}

.thankYouContainer {
  text-align: center;
  border: 5px solid green;
  padding-bottom: 5rem;
  padding-top: 2rem;
  /* margin: 10px; */
  margin-top: 50px;
  border-radius: 7px;
}
.thankYouContainer__gifContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankYouContainer__gifContainer--gif {
  background: url(./thankYou.gif) center center no-repeat;
  top: 0;
  left: 46%;
  background-size: 97% 97%;
  width: 200px;
  height: 200px;
}
.thankYouContainer__msgPara {
  font-size: 20px;
  text-align: center;
}
.thankYouContainer__msgPara--margin {
  margin-top: 20px;
}
.thankYouContainer__heading {
  color: green;
}
.thankYouContainer_Section2{
  margin-top: 1rem;
}
.thankYouContainer_Section2--heading{
  color:blue;
  text-align: center;
  font-size: 20px;
}
.thank_you_btn{
  background-color: #0066FF;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 700;
}