.my-order-searchbar .form-group{
	position: relative;
}
.my-order-searchbar .search-btn{
	position: absolute;
	right: 0;
	top: 0;
	padding: 17px 30px;
	font-size: 14px;
}
.gift-card-text {
    color: #000000;
    font-size: 23px;
    padding-bottom: 24px;
    font-weight: bold;
}
.empty-cart {
	border: 1px solid #ccc;
	margin-top: 12px;
	padding: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
.order-heading {
  background: #eee;
  border: 1px solid #dfdfdf;
  border-radius: 1px;
  padding: 10px;
}

.wishlist-detail {
	border: 1px solid #E1E1E1;
	padding: 30px 20px;
}

.my-order-searchbar .form-control{
	border-radius: 0;
	height: 50px;
	padding-right: 120px;
}
.my-order-img{
	height: 100px;
	width: 100px;
}
.my-order-img img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.my-orders-card{
	border: 1px solid #ced4da;
	padding: 10px 20px;
	margin-bottom: 15px;
}
.my-orders-card p{
	font-size: 12px;
}
.my-orders-card p, .my-orders-card span{
	color: #000000;
	font-weight: 500;
}
.my-order-right h4{
	font-size: 14px;
	color: #000000;
	margin-bottom: 5px;
}
.my-order-info{
	font-size: 14px;
}
.order-price h3{
	font-size: 25px;
	font-weight: 800;
}
.btn{
	cursor: pointer;
}
.my-order-right a{
	color: #262627;
	text-decoration: none;
	font-weight: 700;
	font-size: 12px;
	margin-top: 20px;
	display: block;
}
.checkbox-form-card{
	border:1px solid #ced4da;
	padding: 10px;
}
.checkbox-form-card label{
	font-size: 12px;
	font-weight: 500;
	color: #000000;
}
.checkbox-heading{
	margin-bottom: 10px;
	color: #000;
	font-weight: 600;
	font-size: 14px;
}
.checkbox-form-card h4{
	font-weight: 800;
	margin-bottom: 10px;
}

.checkbox-form{
	margin-top: 50px;
}

.my-order-product-img {
  display: flex;
  height: 100px;
  padding-right: 20px;
  flex: 0 0 130px;
}

.my-order-product-img img {
  height: 100%;
  width: 100%;
}

.product-detils-section .product-advertisment-card{
	height:380px;
	margin: 20px auto;
}
.custom-pagination-wrap{
	border:1px solid #ced4da;
	border-width:1px 0;
	padding: 30px 0;
	font-size: 12px;
	font-weight: 600;
	margin-top: 60px;
}
.custom-pagination-wrap a{
	font-weight: 800;
}
.custom-pagination-wrap ul li {
	margin: 0 10px;
}
.custom-pagination-wrap ul li a{
	color: #000;
}
.custom-pagination-wrap ul li.active a, .custom-pagination-wrap a {
	color: #262627;
}

.my-orders-card-wrap > div {
  margin-bottom: 20px;
}

@media(max-width:991px){
	.my-orders-card {
		padding: 10px;
	}
	.my-orders-card p, .my-orders-card span, .my-order-right a{
		font-size: 10px;
	}
	.order-price h3{
		font-size: 18px;
	}
	.my-order-right h4{
		font-size: 12px;
		margin:0;
	}
  .my-order-content-wrap,.my-order-haeader {
    font-size: 12px;
  }
  .order-heading {
    padding: 10px 5px;
  }
  .my-order-content-wrap .product-text {
    font-size: 12px;
  }
  .my-order-content-wrap .product-name {
    font-size: 14px;
  }
  .wishlist-detail .price-qty {
    display: block;
  }
  .wishlist-detail .price-qty p{
    font-size: 14px;
  }
}

@media(max-width:767px){
	.my-orders-card{
		flex-flow: column;
		justify-content: center;
	}
	.my-order-right, .order-price{
		text-align: center;
	}
  .order-heading {
    font-size: 12px;
  }
  .my-order-content-wrap,.my-order-haeader {
    flex-flow: column;
    justify-content: center;
  }
  .my-order-product-img {
    padding-right: 0px;
    flex: auto;
    width: 100px;
    margin: auto;
  }
  .my-order-haeader div {
    margin: 3px 0;
    text-align: center;
  }
  .product-detail-text {
    font-size: 14px;
    text-align: center;
  }
  .my-order-content-wrap .product-name {
    font-size: 14px;
    padding: 0 0 10px;
  }
  .wishlist-detail .price-qty {
    justify-content: center !important;
  }
  .wishlist-detail .price-qty p {
    font-size: 18px;
  }
  .my-order-content-wrap .product-text {
    line-height: 14px;
  }
}
