.auth-form-wrap {
  max-width: 450px;
  padding: 30px 30px;
  margin: 0 auto;
}
.auth-form-wrap label {
  font-weight: 700;
  font-size: 10px;
}
.auth-form-wrap .form-control {
  border-radius: 0;
  border-color: #000000;
}
.mobile-input select {
  width: 60px;
  margin-right: 10px;
}
.auth-btn-wrap button {
  cursor: pointer;
  padding: 16px 60px;
  width: 100%;
  font-size: 10px;
  text-transform: uppercase;
}
.auth-bottom-linking {
  display: flex;
  justify-content: center;
  border-top: 2px solid #f2f2f2;
  max-width: 700px;
  margin: 0 auto;
}
.auth-bottom-content {
  text-align: center;
  margin-top: 3px;
  width: 90%;
}
.auth-bottom-content a.btn-primary {
  display: inline-block;
  padding: 16px 60px;
  width: 100%;
  margin: 10px 0 60px;
  font-size: 10px;
  text-transform: uppercase;
}

.auth-form-wrap input {
  font-weight: bold !important;
}

.forgot-pwd {
  color: #000000;
  font-weight: 500;
}
.forgot-pwd:hover {
  color: #f7bc06;
}
.auth-signup-btn {
  display: block;
  color: #000;
  margin: 10px 0 0 0 !important;
  font-weight: 700;
}

.auth-section p {
  text-align: center;
  margin: 0px auto 30px;
  max-width: 408px;
  line-height: 23px;
}
.auth-section p a {
  font-weight: 700;
  color: #000;
}

.verifyEmailMsg {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  background-color: #edd381;
}

.verifyEmailMsg p {
  padding: 20px;
}
.auth-form-wrap input,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 0;
  border: 1px solid #000000;
}

.auth-section p.Mui-error {
  margin: 0;
  padding: 0;
  text-align: left;
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}

/* .forgot-pwd {
  margin-top: 15px !important;
  text-decoration: none !important;
  display: inline-block;
} */

.forget-wrap {
  text-align: right;
  margin-top: 15px;
}

.resend-wrap {
  margin: 20px 0 0;
}

.primary_buttonContainer {
  width: 100%;
}

.googleAuthContainer {
  gap: 10px;
}

.googleAndAppleLoginWrapper {
  width: 80%;
  font-weight: bold !important;
  font-size: 17px !important;
}

.authProviderText {
  width: 100%;
}

.authProviderIcon {
  font-size: 20px;
}

@media (max-width: 767px) {
  .auth-form-wrap,
  .auth-bottom-linking {
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .hideMobileView {
    display: none !important;
  }
  .googleAuthContainer {
    gap: 20px;
  }
  .googleAndAppleLoginWrapper {
    width: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 450px) {
  /* Your styles for screens in this range go here */
  .signInWrapper {
    width: 80%;
  }
  .createNewAccount {
    padding: 12px !important;
    font-size: 14px !important;
  }
  .authProviderText {
    font-size: 17px !important;
    font-weight: bold;
  }
  .authProviderIcon {
    font-size: 30px !important;
  }
}
