.shopDetailsContainer {
  margin: 16px;
  padding: 16px;
  /* font-family: "Market Sans,Arial,sans-serif"; */
}

.shopDetailsHeader {
  padding: 32px;
  border-radius: 4px;
  height: 135px;
  text-align: center;
  background-color: rgb(239 239 239);
}

.sellerCategoriesContent {
  padding: 61px 32px 32px 32px;
}
.contentOverFlow {
  padding-top: 10px;
  max-height: 400px;
  overflow: auto;
  padding-right: 16px;
}

.shops_filterButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: #4284f3;
  font-weight: "bold";
  cursor: "pointer";
  margin-top: 10px;
}

.selectedItem {
  font-size: small;
  font-weight: bold;
  color: #000000;
  margin-top: 0.5em;
  cursor: pointer;
}

.unselectedItem {
  font-size: small;
  font-weight: 500; /* Use appropriate value for lighter font weight */
  color: #000000;
  margin-top: 0.5em;
  cursor: pointer;
}

.sellerHeaders {
  font-weight: bold;
  color: #000000;
  font-size: large;
  margin: 10px 0px;
}

.drawer {
  padding: 25px;
}

.SingleProductLayoutForDesktop {
  margin-bottom: 24px;
}

.SingleProductLayoutForMobileView {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 90%;
}

.cardActionsContainerForShopDetails {
  padding: 0px 8px;
  border: 0px 1px 1px 1px solid black;
}

@media (min-width: 1001px) {
  .shops_filterButton {
    display: none;
  }
  .drawer {
    display: none;
  }
  .shopDetailsContent {
    padding: 32px;
  }
  .hideSingleProductLayoutForMobileView {
    display: none;
  }
}

@media (max-width: 1000px) {
  .largeScreenFilters {
    display: none;
  }
  .ShopDetailsWrapper {
    padding: 0px !important;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .tabPanelRoot {
    padding: 5px !important;
  }
  .SingleProductLayoutForDesktop {
    display: none;
  }
}
