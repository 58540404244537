.acc-form {
  width: "100%"; /* Fix IE 11 issue.*/
  margin-top: 24px !important;
}

.submit:hover {
  background: #005ADD !important;
}

.submit {
  margin: 24px 0px 16px !important;
  background: #262627 !important;
  padding: 6px 16px !important;
  color: #ffff !important;
  border-radius: 2px !important;
}

.msg-font-size {
  font-size: 15px;
}

.verify-email.cart-detail {
  margin: 3%;
}

.paper {
  margin-block: 100px;
  padding: 30px;
  border-radius: 10px;
  border: 2px solid #262627;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: #262627;
  margin-top: 45px !important;
  font-size: 30px !important;
  font-weight: bold !important;
}

.phoneInput {
  color: #d32f2f;
  font-size: 0.75rem;
  font-weight: 500;
}

.telInput {
  width: 100%;
  height: 3.9em;
}
.verify-otp-form {
  width: "100%"; /* Fix IE 11 issue.*/
  margin-top: 24px;
}
.verify-button {
  margin: 24px 0px 16px !important;
  background: #262627 !important;
  padding: 6px 16px !important;
  color: #ffff !important;
  border-radius: 2px !important;
}
.updateErrSpan {
  color: #f44336;
  font-size: "12px";
}

.errMsgDiv {
  background: #f2dede;
  color: #a94442;
  margin-bottom: 15px;
  padding-inline: 0;
}

.textFieldNumInput input::-webkit-clear-button, input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  display: none;
}

.msgContainer {
  text-align: center;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msgSuccess {
  background: #dff0d8;
  color: #3c763d;
  font-size: 16px;
}

.msgError {
  background: #f2dede;
  color: #a94442;
}

.msgTxtContainer {
  position: relative;
  top: 40%;
}
